var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.testimonial.testimonials_video && _vm.testimonial.testimonials_video.length
    )?_c('div',{staticClass:"grid grid--lg grid--scrollable",attrs:{"data-test":"testimonials-mixed-video"}},_vm._l((_vm.testimonial.testimonials_video),function(testimonialVideo,index){return _c('div',{key:index,staticClass:"cell cell--lg cell--tab-1of2 shim-mb0 shim-mt0"},[_c('CardPortrait',{attrs:{"title":testimonialVideo.title,"description":testimonialVideo.description,"img-src":testimonialVideo.img_src,"video":{
          duration: testimonialVideo.video.duration,
          url: testimonialVideo.video.url,
          youkuUrl: testimonialVideo.video.url_youku,
          preview: testimonialVideo.poster.preview,
          ariaLabel: testimonialVideo.poster.ariaLabel,
        },"autoplay":""}})],1)}),0):_vm._e(),_vm._v(" "),(
      _vm.testimonial.testimonials_long && _vm.testimonial.testimonials_long.length
    )?_c('div',{attrs:{"data-test":"testimonials-mixed-long"}},_vm._l((_vm.testimonial.testimonials_long),function(testimonialLong,index){return _c('TestimonialsAlt',{key:index,staticClass:"shim-mb1",attrs:{"title":testimonialLong.title,"name":testimonialLong.name,"img-src":testimonialLong.img_src,"long":""}})}),1):_vm._e(),_vm._v(" "),(
      _vm.testimonial.testimonials_text && _vm.testimonial.testimonials_text.length
    )?_c('div',{staticClass:"grid grid--lg",attrs:{"data-test":"testimonials-mixed-text"}},_vm._l((_vm.testimonial.testimonials_text),function(testimonialText,index){return _c('div',{key:index,staticClass:"cell cell--lg cell--tab-1of2 shim-mt0 shim-mb1"},[_c('TestimonialsAlt',{attrs:{"title":testimonialText.title,"name":testimonialText.name,"img-src":testimonialText.img_src}})],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }