<template>
  <div id="personalised-advice" class="bg-alt-darker">
    <ContentBlock>
      <h2>
        {{ $t('component.advice.title') }}
      </h2>
    </ContentBlock>
    <TodoList
      bg-color="alt-darker"
      img="https://matrix-cms.unimelb.edu.au/__data/assets/file/0037/167689/experience.svg"
      :aria-label="$t('component.advice.title')"
      :description="$t('component.advice.intro')"
      :button-card-data="cards"
    />
  </div>
</template>

<script>
import { TodoList, ContentBlock } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    TodoList,
    ContentBlock,
  },
  computed: {
    cards() {
      return [
        {
          label: this.$t('component.advice.btn1'),
          link: 'https://forms.your.unimelb.edu.au/4746909',
          inverted: false,
        },
        {
          label: this.$t('component.advice.btn2'),
          link: 'https://study.unimelb.edu.au/connect-with-us/receive-updates-from-melbourne',
          inverted: false,
        },
        {
          label: this.$t('component.advice.btn3'),
          link: 'https://study.unimelb.edu.au/student-life/events',
          inverted: false,
        },
      ];
    },
  },
};
</script>
