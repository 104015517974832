<template>
  <div>
    <div
      v-if="
        testimonial.testimonials_video && testimonial.testimonials_video.length
      "
      class="grid grid--lg grid--scrollable"
      data-test="testimonials-mixed-video"
    >
      <div
        v-for="(testimonialVideo, index) in testimonial.testimonials_video"
        :key="index"
        class="cell cell--lg cell--tab-1of2 shim-mb0 shim-mt0"
      >
        <CardPortrait
          :title="testimonialVideo.title"
          :description="testimonialVideo.description"
          :img-src="testimonialVideo.img_src"
          :video="{
            duration: testimonialVideo.video.duration,
            url: testimonialVideo.video.url,
            youkuUrl: testimonialVideo.video.url_youku,
            preview: testimonialVideo.poster.preview,
            ariaLabel: testimonialVideo.poster.ariaLabel,
          }"
          autoplay
        />
      </div>
    </div>
    <div
      v-if="
        testimonial.testimonials_long && testimonial.testimonials_long.length
      "
      data-test="testimonials-mixed-long"
    >
      <TestimonialsAlt
        v-for="(testimonialLong, index) in testimonial.testimonials_long"
        :key="index"
        :title="testimonialLong.title"
        :name="testimonialLong.name"
        :img-src="testimonialLong.img_src"
        long
        class="shim-mb1"
      />
    </div>
    <div
      v-if="
        testimonial.testimonials_text && testimonial.testimonials_text.length
      "
      class="grid grid--lg"
      data-test="testimonials-mixed-text"
    >
      <div
        v-for="(testimonialText, index) in testimonial.testimonials_text"
        :key="index"
        class="cell cell--lg cell--tab-1of2 shim-mt0 shim-mb1"
      >
        <TestimonialsAlt
          :title="testimonialText.title"
          :name="testimonialText.name"
          :img-src="testimonialText.img_src"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TestimonialsAlt, CardPortrait } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    TestimonialsAlt,
    CardPortrait,
  },
  props: {
    testimonial: {
      type: Object,
      required: true,
    },
  },
};
</script>
