<template>
  <SectionAlt id="related-links" bg-color="alt">
    <h2 class="title title--md">
      {{ $t('component.related-pages.title') }}
    </h2>
    <CardArticleList :items="items" class="related-pages-list" />
  </SectionAlt>
</template>

<script>
import { CardArticleList, SectionAlt } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    CardArticleList,
    SectionAlt,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
      validator: (value) => value.length,
    },
  },
};
</script>

<style lang="postcss">
.related-pages-list .push-icon__icon {
  display: none;
}
</style>
